import React, { useState } from "react";
import PropTypes from "prop-types";
import iconDiscord from "../../../res/icon-user-website/discord.svg";
import iconFacebook from "../../../res/icon-user-website/facebook.svg";
import iconInstagram from "../../../res/icon-user-website/instagram.svg";
import iconReddit from "../../../res/icon-user-website/reddit.svg";
import iconTwitter from "../../../res/icon-user-website/twitter.svg";
import iconYouTube from "../../../res/icon-user-website/youtube.svg";
import iconTwitch from "../../../res/icon-user-website/twitch.svg";
import SaveCancelButtons from "./SaveCancelButtons";
import merge from "lodash/merge";
import { Field, Form, Formik } from "formik";
import { object as yupObject, string as yupString } from "yup";
import {
  CardRow,
  DdTextField,
  DescriptionDetailsTextField,
  DescriptionTerm,
  DtImg,
  DtTypography,
} from "../SettingsPaperElements";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const explainer = ["Set links to your social media channels which will appear in your website's footer."];
const fields = {
  discord: { name: "discord", caption: "Discord", icon: iconDiscord },
  facebook: { name: "facebook", caption: "Facebook", icon: iconFacebook },
  instagram: { name: "instagram", caption: "Instagram", icon: iconInstagram },
  reddit: { name: "reddit", caption: "Reddit", icon: iconReddit },
  twitter: { name: "twitter", caption: "Twitter", icon: iconTwitter },
  youtube: { name: "youtube", caption: "YouTube", icon: iconYouTube },
  twitch: { name: "twitch", caption: "Twitch", icon: iconTwitch },
};
const emptyValues = {
  discord: "",
  facebook: "",
  instagram: "",
  reddit: "",
  twitter: "",
  youtube: "",
  twitch: "",
};
const validationHint = "Enter a valid URL";
const validationSchema = yupObject({
  discord: yupString().url(validationHint),
  facebook: yupString().url(validationHint),
  instagram: yupString().url(validationHint),
  reddit: yupString().url(validationHint),
  twitter: yupString().url(validationHint),
  youtube: yupString().url(validationHint),
  twitch: yupString().url(validationHint),
});


const FormSocialMedia = ({ data, saveData }) => {

  // initialValues as state necessary for use case:
  // save new data, reset => reset form needs to show newly saved data
  const [initialValues, setInitialValues] = useState(merge(emptyValues, data));
  const classes = useStyles();


  const submitForm = (values, actions) => {
    const newData = { socialMedia: { ...values } };
    saveData(newData, values, actions, setInitialValues);
  };


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ dirty, isValid, isSubmitting }) => {

        return (
          <Form>
            <Typography variant="body1">{explainer[0]}</Typography>
            <dl>
              {Object.keys(fields).map((el) => {
                return (
                  <CardRow key={fields[el].name}>
                    <DescriptionTerm>
                      <DtImg src={fields[el].icon} alt={fields[el].caption} />
                      <DtTypography variant="subtitle1">{fields[el].caption}</DtTypography>
                    </DescriptionTerm>
                    <DescriptionDetailsTextField>
                      <Field name={fields[el].name}
                             type="url"
                             component={DdTextField}
                             variant="outlined"
                             margin="dense"
                             placeholder="https://..."
                             FormHelperTextProps={{ component: "span", classes: classes }}
                      />
                    </DescriptionDetailsTextField>
                  </CardRow>
                );
              })}
            </dl>
            <SaveCancelButtons dirty={dirty}
                               isValid={isValid}
                               isSubmitting={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
FormSocialMedia.propTypes = {
  data: PropTypes.object.isRequired,
  saveData: PropTypes.func.isRequired,
};
export default FormSocialMedia;

const useStyles = makeStyles({
  error: {
    height: 0,
    overflow: "visible",
  },
});