import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import styled from "styled-components";
import {
  CardRow,
  DescriptionDetails,
  DescriptionTerm,
  DtTypography,
} from "../SettingsPaperElements";
import DoneIcon from "@material-ui/icons/Done";
import { AuthContext } from "../../auth/AuthContextProvider";
import { logError } from "../../element/error/sentry";
import { makeStyles } from "@material-ui/core/styles";
import LoadingSpinner from "../../element/graphical/LoadingSpinner";
import { oo } from "../../element/theme";


const explainer = [
  "Upload your game art that will be shown at the top of the web page. This will be the first impression a visitor gets of your website and your game.",
];
const dialogTxt = {
  dropzoneText: "Drop file or click to upload",
  cancelButtonText: "Cancel",
  submitButtonText: "Confirm",
  instructions: [
    ["Allowed image types", "acceptedFiles"],
    ["Recommended aspect ratio", "aspectRatio"],
    ["Recommended maximum resolution", "maximumResolution"],
    ["Recommended minimum resolution", "minimumResolution"],
    ["Maximum file size", "maxFileSize"],
  ],
};
const fields = {
  icon: {
    name: "icon",
    caption: "Game icon",
    filesLimit: 1,
    dialogTitle: "Upload game icon",
    isSaved: false,
    isSavedFeedback: "Icon saved",
    acceptedFiles: [".png", ".jpg", ".jpeg", ".svg"],
    maxFileSize: 200000,
    aspectRatio: "1:1 — 2:1",
    maximumResolution: "400 x 400 px",
    minimumResolution: "150 x 150 px",
  },
  hero: {
    name: "hero",
    caption: "Game banner",
    filesLimit: 1,
    dialogTitle: "Upload game banner",
    isSaved: false,
    isSavedFeedback: "Banner saved",
    acceptedFiles: [".png", ".jpg", ".jpeg"],
    maxFileSize: 6000000,
    aspectRatio: "16:9",
    maximumResolution: "3840 x 2160 px",
    minimumResolution: "1280 x 720 px",
  },
};
const buildFilePath = (uid, subfolder, fileName) => {
  return `/users/${uid}/${subfolder}/${fileName}`;
};
const buildFolderPath = (uid, subfolder) => {
  return `/users/${uid}/${subfolder}/`;
};

const FormStorage = () => {
  const classes = useStyles();
  const uid = useContext(AuthContext).getUser().uid;
  const storageRef = useContext(AuthContext).getStorage().ref();

  const [open, setOpen] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [currentDialog, setCurrentDialog] = useState("icon");
  const [currentFields, setCurrentFields] = useState(fields);
  const [fileObjects, setFileObjects] = useState({ icon: [], hero: [] });
  const [loading, setLoading] = useState(false);


  const handleIsSaved = (currentDialog, isSaved) => {
    const newFormState = currentFields;
    newFormState[currentDialog].isSaved = isSaved;
    setCurrentFields(newFormState);
  };

  useEffect(() => {

    async function fetchStorage() {
      let iconStorageRef = storageRef.child(buildFolderPath(uid, "icon"));
      let heroStorageRef = storageRef.child(buildFolderPath(uid, "hero"));
      let iconFileObject;
      let heroFileObject;

      try {
        const iconListResult = await iconStorageRef.list();
        const heroListResult = await heroStorageRef.list();

        if (iconListResult.items.length > 0) {
          const metadata = await iconListResult.items[0].getMetadata();
          const file = new File([], metadata.name);
          iconFileObject = [{ data: "", file: file }];
          handleIsSaved("icon", true);
        }
        if (heroListResult.items.length > 0) {
          const metadata = await heroListResult.items[0].getMetadata();
          const file = new File([], metadata.name);
          heroFileObject = [{ data: "", file: file }];
          handleIsSaved("hero", true);
        }
        setFileObjects({ icon: iconFileObject, hero: heroFileObject });
      } catch (error) {
        logError(error);
      }
    }

    fetchStorage();
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const handleDisabled = (fileObjects) => {
    if (fileObjects.length > 0) setUploadDisabled(false);
    else setUploadDisabled(true);
  };

  const onConfirm = async () => {
    setLoading(true);
    // [give error feedback upload/delete] => isSaved in wrong state if error
    await handleUpload(fileObjects[currentDialog][0].file);
    handleIsSaved(currentDialog, true);
    setOpen(false);
    setLoading(false);
  };

  const deleteFile = async (path) => {
    try {
      await storageRef.child(path).delete();
    } catch (error) {
      logError(error, { uid: uid });
    }
  };

  const handleUpload = async (file) => {
    // delete existing files
    let existingFileRef = storageRef.child(buildFolderPath(uid, currentDialog));
    const existingFileListResult = await existingFileRef.list();
    if (existingFileListResult.items.length > 0) {
      await deleteFile(existingFileListResult.items[0].location.path_);
    }

    // upload file
    const path = buildFilePath(uid, currentDialog, file.name);
    try {
      await storageRef.child(path).put(file);
    } catch (error) {
      logError(error);
    }
    handleIsSaved(currentDialog, true);
  };

  const handleDelete = async (currentDialog) => {
    // [give error feedback upload/delete] => isSaved in wrong state if error
    const path = buildFilePath(uid, currentDialog, fileObjects[currentDialog][0].file.name);
    await deleteFile(path);
    handleIsSaved(currentDialog, false);
    setFileObjects(prevState => ({
      ...prevState,
      [currentDialog]: [],
    }));
  };


  return (
    <div>
      <Typography component="span" variant="body1">{explainer[0]}</Typography>
      <dl>

        {/* --- Initially visible form --- */}
        {Object.keys(currentFields).map((el) => {

          let chip = null;
          let deleteButton = null;
          if (currentFields[el].isSaved) {
            chip = (<Chip label={currentFields[el].isSavedFeedback}
                          style={{ marginRight: "0.3rem" }}
                          avatar={<DoneIcon />} />);
            deleteButton = (
              <AddDeleteReplaceButton
                variant="outlined"
                color="primary"
                onClick={() => handleDelete(currentFields[el].name)}>
                Delete
              </AddDeleteReplaceButton>
            );
          }

          return (
            <CardRow key={currentFields[el].name}>
              <DescriptionTerm>
                <DtTypography variant="subtitle1">{currentFields[el].caption}</DtTypography>
              </DescriptionTerm>

              <DescriptionDetails>
                {chip}
                {deleteButton}

                <AddDeleteReplaceButton
                  variant={currentFields[el].isSaved ? "outlined" : "contained"}
                  color="primary"
                  onClick={() => {
                    setOpen(true);
                    setCurrentDialog(currentFields[el].name);
                  }}>
                  {currentFields[el].isSaved ? "Replace" : "Add"}
                </AddDeleteReplaceButton>
              </DescriptionDetails>
            </CardRow>
          );
        })}


        {/* --- Initially invisible dialog --- */}
        <Dialog fullWidth
                maxWidth="sm"
                onClose={onClose}
                open={open}>

          <DialogHeader>
            <Typography component="h2" variant="h5" gutterBottom>
              {currentFields[currentDialog].dialogTitle}
            </Typography>
            <Divider />
            <dl>
              {dialogTxt.instructions.map((el, index) => {
                let ddContent = currentFields[currentDialog][el[1]];
                if (el[1] === "acceptedFiles") {
                  ddContent = currentFields[currentDialog][el[1]].join(", ");
                }
                if (el[1] === "maxFileSize") {
                  ddContent = `${currentFields[currentDialog][el[1]] / 1000000} MB`;
                }
                return (
                  <DialogCardRow key={index}>
                    <DescriptionTerm>
                      <DtTypography variant="subtitle1">{el[0]}</DtTypography>
                    </DescriptionTerm>
                    <DialogDescriptionDetails>
                      <Typography variant="subtitle1">{ddContent}</Typography>
                    </DialogDescriptionDetails>
                  </DialogCardRow>
                );
              })}
            </dl>
          </DialogHeader>

          <DialogContent>
            <DropzoneAreaBase
              fileObjects={fileObjects[currentDialog]}
              acceptedFiles={currentFields[currentDialog].acceptedFiles}
              dropzoneText={dialogTxt.dropzoneText}
              useChipsForPreview={true}
              previewChipProps={{ disabled: true, deleteIcon: <DoneIcon /> }}
              showFileNames={true}
              previewGridProps={{ container: { justify: "center" } }}
              filesLimit={currentFields[currentDialog].filesLimit}
              maxFileSize={currentFields[currentDialog].maxFileSize}
              onChange={(files) => console.log("onChange files: ", files)}
              onAdd={newFileObjs => {
                setFileObjects(prevState => ({
                  ...prevState,
                  [currentDialog]: [].concat([], newFileObjs),
                }));
                handleDisabled(newFileObjs);
              }}
              classes={{
                root: classes.smDropzone,
              }}
            />
          </DialogContent>

          <DialogActions>
            {
              loading &&
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button disabled>Loading</Button>
                <LoadingSpinner color={oo.grey5} fontSize="1rem" margin="0.7rem" />
              </div>
            }
            {
              !loading &&
              <>
                <Button color="primary" onClick={onClose}>
                  {dialogTxt.cancelButtonText}
                </Button>
                <Button color="primary" variant="outlined"
                        disabled={uploadDisabled} onClick={onConfirm}>
                  {dialogTxt.submitButtonText}
                </Button>
              </>
            }
          </DialogActions>
        </Dialog>

      </dl>
    </div>
  );
};
export default FormStorage;

const DialogHeader = styled.div`
  padding: 1.5rem 1rem 0 1rem;
`;
const DialogCardRow = styled.div`
  display: flex;
  align-items: center;
`;
const DialogDescriptionDetails = styled.dd`
  flex: 10%;  
`;
const AddDeleteReplaceButton = styled(Button)`
  margin: 0.3rem;
`;
const useStyles = makeStyles({
  smDropzone: {
    minHeight: "13rem",
  },
});
