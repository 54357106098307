import React from "react";
import PropTypes from "prop-types";
import { oo } from "../element/theme";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const EmphasizeBox = ({ label }) => {
  return (
    <EmphasizeContainer outline={`2px solid ${oo.textErrorMain}`}>
      <Typography component="span" variant="subtitle2">{label}</Typography>
    </EmphasizeContainer>
  );
};
EmphasizeBox.propTypes = {
  label: PropTypes.string.isRequired,
};
export default EmphasizeBox;


const EmphasizeContainer = styled.div`
  margin-top: 1rem;
  padding: 0.2rem 1rem;
  outline: ${props => props.outline};
  color: ${oo.textErrorMain};
  text-align: center;
`;