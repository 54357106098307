import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";

const SaveCancelButtons = ({ dirty, isValid, isSubmitting }) => {
  let disabledSave = true;
  let disabledReset = true;
  if (dirty && (isValid && !isSubmitting)) disabledSave = false;
  if ((dirty || !isValid) && !isSubmitting) disabledReset = false;

  return (
    <PersistenceContainer>
      <ButtonSt variant="contained" color="primary"
                disabled={disabledSave}
                type="submit"
      >
        Save
      </ButtonSt>
      <ButtonSt variant="outlined" color="primary"
                disabled={disabledReset}
                type="reset"
      >
        Cancel
      </ButtonSt>
    </PersistenceContainer>
  );
};
SaveCancelButtons.propTypes = {
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
export default SaveCancelButtons;

const PersistenceContainer = styled.div`
  display: flex;
`;
const ButtonSt = styled(Button)`
  margin-right: 2rem;
  width: 5rem;
`;