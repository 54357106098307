import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { oo } from "../../element/theme";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import _merge from "lodash/merge";
import { Field, Form, Formik } from "formik";
import SaveCancelButtons from "./SaveCancelButtons";
import { boolean as yupBoolean, object as yupObject, string as yupString } from "yup";
import { TextField } from "formik-material-ui";
import { requestWebsiteDataPreview } from "../../rest/request-website-data";
import _get from "lodash/get";
import SelectGamePreview from "./SelectGamePreview";


const txt = [
  "https://store.steampowered.com/app/...",
  "Search",
];
const feedbackValues = {
  initial: {
    isError: false,
    helperText: "Copy & paste the Steam URL of your game",
  },
  error: {
    isError: true,
    helperText: "Not a valid Steam URL. Copy & paste the Steam URL of your game directly from your browser.",
  },
};
const emptyValues = {
  searchValue: "",
  isSearchTriggered: false,
  gameName: "",
  url: "",
  header: "",
};
const hintSteamUrl = feedbackValues.error.helperText;
const hintRequired = "A Steam URL is required to search.";
const validationSchema = yupObject({
  searchValue:
    yupString(hintRequired).trim().url(hintSteamUrl)
      .matches(/^https:\/\/store\.steampowered\.com\/app\/\d+\/?.*/,
        { message: hintSteamUrl, excludeEmptyString: true }),
  isSearchTriggered: yupBoolean().required().oneOf([true]),
  gameName: yupString(),
  url: yupString(),
  header: yupString(),
});

const FormSelectGame = ({ data, saveData }) => {
  const classesInputLabel = useStylesInputLabel();
  const classesFormHelperText = useStylesFormHelperText();

  const tempValues = { ...emptyValues };
  if (data.url) tempValues.searchValue = data.url;
  const [initialValues, setInitialValues] = useState(_merge(tempValues, data));


  const handleSearch = async (searchValue, setValues) => {
    if (searchValue.length > 0) {
      const res = await requestWebsiteDataPreview(searchValue);
      const newValues = {
        searchValue: searchValue,
        isSearchTriggered: true,
        gameName: _get(res, "data.websiteData.gameName", ""),
        url: searchValue,
        header: _get(res, "data.websiteData.header", ""),
      };
      setValues(newValues);
    } else {
      const newValues = { ...emptyValues, isSearchTriggered: true };
      setValues(newValues);
    }
  };

  // make sure search field enter press does not trigger submitting the form
  const onKeyDown = (keyEvent, searchValue, setValues) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
      handleSearch(searchValue, setValues);
    }
  };

  // handle reset button
  const handleReset = (setValues) => {
    handleSearch("", setValues);
  };
  const handleMouseDownReset = (event) => {
    event.preventDefault();
  };


  const submitForm = (values, actions) => {
    let newData;
    if (values.searchValue.length > 0) {
      newData = {
        game: {
          gameName: values.gameName,
          url: values.url,
          header: values.header,
        },
      };
    } else {
      newData = { game: {} };
    }

    // make sure form is not instantly submittable again when saved
    const newValues = { ...values, isSearchTriggered: false };
    saveData(newData, newValues, actions, setInitialValues);
  };


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({
          dirty,
          isValid,
          isSubmitting,
          values,
          setValues,
        }) => {

        return (
          <Form onKeyDown={(event) => onKeyDown(event, values.searchValue, setValues)}>
            <Container>
              <SearchRow>
                <Field name="searchValue"
                       type="url"
                       component={TextField}
                       fullWidth
                       variant="outlined"
                       margin="dense"
                       label={txt[0]}
                       InputLabelProps={{ classes: classesInputLabel }}
                       helperText={feedbackValues.initial.helperText}
                       FormHelperTextProps={{ component: "span", classes: classesFormHelperText }}
                       InputProps={{
                         endAdornment: (
                           <InputAdornment position="end">
                             <Tooltip title="Cancel">
                               <IconButton size="small"
                                           onClick={() => handleReset(setValues)}
                                           onMouseDown={handleMouseDownReset}>
                                 <ClearIcon />
                               </IconButton>
                             </Tooltip>
                           </InputAdornment>),
                       }}
                />
                <ButtonSt variant="outlined"
                          color="primary"
                  // disabled={!isValid}
                          startIcon={<SearchIcon />}
                          onClick={() => handleSearch(values.searchValue, setValues)}
                >
                  {txt[1]}
                </ButtonSt>
              </SearchRow>
              <SelectGamePreview values={values} dirty={dirty} />
              <SaveCancelButtons dirty={dirty}
                                 isValid={isValid}
                                 isSubmitting={isSubmitting}
              />
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};
FormSelectGame.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};
export default FormSelectGame;


const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
const SearchRow = styled.div`
  display: flex;
  align-items: center;
  height: 5rem;
`;
const ButtonSt = styled(Button)`
  margin-left: 2rem;
  height: 2.55rem;
  padding: 0 1.5rem;
`;


const useStylesInputLabel = makeStyles({
  root: {
    color: oo.textColorOnLight,
  },
});
const useStylesFormHelperText = makeStyles({
  root: {
    color: oo.textColorOnLight,
    height: 0,
    overflow: "visible",
  },
});


