import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { oo } from "../../element/theme";
import EmphasizeBox from "../EmphasizeBox";


const txt = [
  "Build a website for the following game:",
  "Save your selection",
  "",
  "🡩 We cannot build a website for you without a game selection! 🡩",
];

const SelectGamePreview = ({ values, dirty }) => {

  if ((values.searchValue.length === 0) || (values.url.length === 0)) {
    return <EmphasizeBox label={txt[3]} />;
  }

  let outline, saveNotice;
  if (dirty) {
    outline = `1px solid ${oo.textErrorMain}`;
    saveNotice = txt[1];
  } else {
    outline = "none";
    saveNotice = txt[2];
  }

  // [game preview fetch failed feedback] test by not having antenna online.

  return (
    <SelectGamePreviewContainer>
      <Typography component="h4" variant="h6">{txt[0]}</Typography>
      <PreviewRow outline={outline}>
        <ImgSt src={values.header} alt="Steam game header" />
        <div>
          <Typography variant="subtitle1">{values.gameName}</Typography>
          <Typography variant="body1">{values.url}</Typography>
        </div>
      </PreviewRow>
      <TypographySt variant="body1" color="error">{saveNotice}</TypographySt>
    </SelectGamePreviewContainer>
  );
};
SelectGamePreview.propTypes = {
  values: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
};
export default SelectGamePreview;


const SelectGamePreviewContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 1.5rem 0 0 0;
`;
const ImgSt = styled.img`
  margin: 0 1rem 0 0;  
  height: 7rem;
  width: 15rem;
`;
const PreviewRow = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  outline: ${props => props.outline};
`;
const TypographySt = styled(Typography)`
  height: 2rem;
`;