import ky from "ky";
import { logError } from "../element/error/sentry";
import endpoint from "./endpoint";

export const requestWebsiteDataPreview = async (url) => {
  let res;
  const requestOptions = {
    json: {
      url: url,
      key: process.env.GATSBY_OBERION_PREVIEW_PUBLIC_KEY,
    },
  };
  try {
    res = await ky.post(endpoint.previewWebsiteData, requestOptions).json();
    return res;
  } catch (error) {
    let body = {};
    if (error instanceof ky.HTTPError) {
      body = await error.response.json();
      if ((error.response.status >= 400) && (error.response.status <= 499)) {
        return body;
      }
    }
    // console.log("error", error);
    logError(error, { ...requestOptions.json, ...body });
    return null;
  }
};