import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import {
  CardRow,
  DdTextField,
  DescriptionDetailsTextField,
  DescriptionTerm,
  DescriptionTermColumn,
  DtTypography,
} from "../SettingsPaperElements";
import { object as yupObject, string as yupString } from "yup";
import { Field, Form, Formik } from "formik";
import SaveCancelButtons from "./SaveCancelButtons";
import merge from "lodash/merge";
import { makeStyles } from "@material-ui/core/styles";


const explainer = ["Depending on your jurisdiction, you might be required to display contact and legal information about your studio on your website."];
const specialLegalNoticeField = ["Legal Notice", "This is a good place for an imprint."];
const fields = {
  studioName: {
    name: "studioName", caption: "Studio name", placeholder: "Great Game Studios",
    multiline: false, rows: undefined, rowsMax: undefined,
  },
  contact: {
    name: "contact", caption: "Contact", placeholder: "contact@greatgamestudio.com",
    multiline: false, rows: undefined, rowsMax: undefined,
  },
  legalNotice: {
    name: "legalNotice",
    caption: "Legal Notice",
    placeholder: "Studio name, address, country, etc.",
    multiline: true, rows: 4, rowsMax: 10,
  },
};
const emptyValues = {
  studioName: "",
  contact: "",
  legalNotice: "",
};
const validationSchema = yupObject({
  studioName: yupString(),
  contact: yupString(),
  legalNotice: yupString(),
});


const FormLegal = ({ data, saveData }) => {

  // initialValues as state necessary for use case:
  // save new data, reset => reset form needs to show newly saved data
  const [initialValues, setInitialValues] = useState(merge(emptyValues, data));
  const classes = useStyles();


  const submitForm = (values, actions) => {
    const newData = { legal: { ...values } };
    saveData(newData, values, actions, setInitialValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({ dirty, isValid, isSubmitting }) => {

        return (
          <Form>
            <Typography component="span" variant="body1">{explainer[0]}</Typography>
            <dl>
              {Object.keys(fields).map((el) => {

                let dtElement;
                if (fields[el].name === "legalNotice") {
                  dtElement = (
                    <DescriptionTermColumn>
                      <DtTypography variant="subtitle1">{specialLegalNoticeField[0]}</DtTypography>
                      <Typography variant="caption">{specialLegalNoticeField[1]}</Typography>
                    </DescriptionTermColumn>
                  );
                } else {
                  dtElement = (
                    <DescriptionTerm>
                      <DtTypography variant="subtitle1">{fields[el].caption}</DtTypography>
                    </DescriptionTerm>
                  );
                }

                return (
                  <CardRow key={fields[el].name}>
                    {dtElement}
                    <DescriptionDetailsTextField>
                      <Field name={fields[el].name}
                             type="text"
                             component={DdTextField}
                             variant="outlined"
                             margin="dense"
                             placeholder={fields[el].placeholder}
                             FormHelperTextProps={{
                               component: "span",
                               classes: classes,
                             }}
                             multiline={fields[el].multiline}
                             rows={fields[el].rows}
                             rowsMax={fields[el].rowsMax}
                      />
                    </DescriptionDetailsTextField>
                  </CardRow>
                );
              })}
            </dl>
            <SaveCancelButtons dirty={dirty}
                               isValid={isValid}
                               isSubmitting={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default FormLegal;
FormLegal.propTypes = {
  data: PropTypes.object.isRequired,
  saveData: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  error: {
    height: 0,
    overflow: "visible",
  },
});