import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { TextField } from "formik-material-ui";

/**
 * formik-material-ui documentation:
 * @see https://stackworx.github.io/formik-material-ui/docs/guide/getting-started
 * Example codesandbox:
 * @see https://codesandbox.io/s/915qlr56rp?file=/src/index.tsx:2729-2749
 *
 * Using Material UI components without this dependency:
 * @see https://dev.to/finallynero/react-form-using-formik-material-ui-and-yup-2e8h
 * @see https://medium.com/make-it-heady/react-typescript-hooks-form-validation-with-formik-yup-and-material-ui-d4901efc0096
 * @see https://medium.com/@nphivu414/build-a-multi-step-form-with-react-hooks-formik-yup-and-materialui-fa4f73545598
 */

export const CardRowBasic = styled.div`
  display: flex;
  padding: 0.25rem 0;
  align-items: center;
`;
export const CardRow = styled(CardRowBasic)`
  min-height: 3.5rem;
`;
export const DescriptionTerm = styled.dt`
  flex: 25%;
  display: flex;
`;
export const DescriptionTermColumn = styled(DescriptionTerm)`
  flex-flow: column;
`;
export const DescriptionDetails = styled.dd`
  flex: 75%;
`;
export const DescriptionDetailsTextField = styled(DescriptionDetails)`
  // prevents shifting down of UI elements when error feedback appears
  min-height: 3.5rem;
`;

export const DtImg = styled.img`
  height: 1.75rem;
  width: auto;
  margin-right: 0.5rem;
  flex: 15%;
`;
export const DtTypography = styled(Typography)`
  flex: 85%;
  &::after {
    content: ":";
  }
`;
export const DdTextField = styled(TextField)`
  width: 100%;
  max-width: 25rem;
`;