import React, { useContext, useEffect, useState } from "react";
import SettingsSection from "../SettingsSection";
import SettingsPaper from "../SettingsPaper";
import FormSelectGame from "./FormSelectGame";
import FormStorage from "./FormStorage";
import FormSocialMedia from "./FormSocialMedia";
import FormLegal from "./FormLegal";
import { AuthContext } from "../../auth/AuthContextProvider";
import paths from "../../firestore/paths";
import { logError, logMessage } from "../../element/error/sentry";
import GettingStarted, { gettingStartedSteps } from "../GettingStarted";
import routes from "../../navigation/routes";
import StepButton from "../StepButton";
import _get from "lodash/get";


const customizeTxt = [
  "Create your website",
  "Add information which are not available on Steam. Except for selecting the game, none of these settings are required but they make your website look more professional.",
  "Select game",
  "Images",
  "Social media",
  "Contact and legal information",
];
const ctaButtonTxt = gettingStartedSteps[1][1];
const emptyUserDoc = {
  game: {},
  legal: {},
  socialMedia: {},
};


const CreateWebsite = () => {
  const authContext = useContext(AuthContext);
  const uid = authContext.getUser().uid;
  const db = authContext.getFirestoreDb();
  const [userDoc, setUserDoc] = useState(emptyUserDoc);


  useEffect(() => {

    async function fetchData() {
      let firestoreDoc;
      try {
        for (let retries = 0; retries < 2; retries++) {
          firestoreDoc = await db.collection(paths.users).doc(uid).get();
          if (firestoreDoc.exists) break;
        }
      } catch (error) {
        logError(error);
      }

      if (firestoreDoc.exists) {
        const freshUserDoc = firestoreDoc.data();
        setUserDoc(freshUserDoc);
        if (_get(freshUserDoc, "game.url", false)) authContext.setGame(freshUserDoc.game);

      } else {
        logMessage("No document detected where it should exist.", { uid: uid });
      }
    }

    fetchData();
    authContext.setNewUser(false); // new user behavior not needed anymore
  }, []);


  const saveData = async (newData, values, actions, setInitialValues) => {
    try {
      await db.collection(paths.users).doc(uid).update(newData);
      setInitialValues(values);
      actions.resetForm({ values: values });
      authContext.setGame(newData.game);
      return true;
    } catch (error) {
      // [show error feedback] to user that save did not work
      logError(error, { uid: uid, data: newData });
      return false;
    }
  };


  return (
    <>
      <GettingStarted currentStep={1} />

      <SettingsSection heading={customizeTxt[0]} subheading={customizeTxt[1]}>
        <SettingsPaper heading={customizeTxt[2]}>
          <FormSelectGame data={userDoc.game} saveData={saveData} />
        </SettingsPaper>
        <SettingsPaper heading={customizeTxt[3]}>
          <FormStorage />
        </SettingsPaper>
        <SettingsPaper heading={customizeTxt[4]}>
          <FormSocialMedia data={userDoc.socialMedia} saveData={saveData} />
        </SettingsPaper>

        <SettingsPaper heading={customizeTxt[5]}>
          <FormLegal data={userDoc.legal} saveData={saveData} />
        </SettingsPaper>
      </SettingsSection>

      <StepButton route={routes.user.sites.deploy.to} caption={ctaButtonTxt} />
    </>
  );
};
export default CreateWebsite;
